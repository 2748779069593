import { contentInfo, menuItems } from "@/cms/ContentPageStructure";
import { useMeasure } from "@/hooks";
import * as Icons from "@/icons";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AnimatePresence } from "framer-motion";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { createRef, Fragment, useEffect, useMemo, useState } from "react";
import { renderMetaTags, SeoOrFaviconTag } from "react-datocms";
import * as TUI from "theme-ui";
import { Tag } from "../../generated/graphql";
import { useMessages } from "../../lib/intl";
import { contentPageLinkProps, homeLinkProps } from "../../lib/links";
import { GlobalNotification } from "../GlobalNotification";
import { LayoutSection } from "../layout";
import { LocaleSwitcher } from "../LocaleSwitcher";
import { MotionBox, springConfigs } from "../motion";
import { favicon } from "./data";
import HeaderHoverableMenuItem from "./HeaderHoverableMenuItem";

const HEADER_MENU_ITEM_MARGIN_RIGHT = "54px";

interface Props extends TUI.BoxProps {
  noindex?: boolean;
  metaTags?: Tag[];

  /**
   * Alternates are links to the same page in other locales. This is used for:
   *
   *  - Alternate links in <head> (<link rel="alternate" … >)
   *  - LocaleSwitcher
   */
  alternates?: { hrefLang: string; href: string }[];

  subtitle?: string;

  /**
   * The 'pageId' is passed down to GlobalNotification which uses it to
   * determine whether the global notification should be suppressed.
   *
   * It is also used to generate the canonical URL link tag.
   */
  pageId?: string;

  /**
   * If set then the global notification is suppressed. Use this instaed of the
   * 'pageId' prop if you want to render a page which dosen't exist in DatoCMS.
   */
  noGlobalNotification?: boolean;

  canonical?: string;
}

const orderedLocales: Record<string, number> = { de: 4, fr: 3, it: 2, en: 1 };

function Header(props: Props) {
  const { noindex, metaTags = [], alternates: _alternates = [], pageId, noGlobalNotification, canonical, sx } = props;

  const [menuRef, { width }] = useMeasure<HTMLDivElement>();
  const [isExpanded, setIsExpanded] = useState(false);
  const router = useRouter();
  const { t } = useMessages();

  const headerMenuItems = useMemo(
    () => menuItems(router.locale).filter((d) => !d.isVirtual || d.children.length),
    [menuItems, router.locale],
  );

  const alternates = [..._alternates].sort((a, b) => orderedLocales[b.hrefLang] - orderedLocales[a.hrefLang]);

  const isNarrow = width < 1300;

  const scrollRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const { current } = scrollRef;
    current && isExpanded ? disableBodyScroll(current) : clearAllBodyScrollLocks();
  }, [isExpanded]);

  return (
    <TUI.Box as="header" data-datocms-noindex>
      <Head>
        {noindex && <meta name="robots" content="noindex" />}
        {renderMetaTags(metaTags as SeoOrFaviconTag[])}
        {renderMetaTags(
          favicon.map((d, i) => ({
            ...d,
            attributes: {
              ...d.attributes,
              name: d.attributes?.rel + d.attributes?.sizes || i,
            },
          })) as SeoOrFaviconTag[],
        )}
        {alternates.map((p, i) => (
          <link key={i} rel="alternate" {...p} />
        ))}
        {(() => {
          if (canonical) {
            return <link key="canonical" rel="canonical" href={canonical} />;
          } else if (pageId) {
            return (
              <link
                key="canonical"
                rel="canonical"
                href={`${process.env.URL}${contentPageLinkProps(router.locale, { id: pageId }).as}`}
              />
            );
          } else {
            return null;
          }
        })()}
      </Head>

      {router.isPreview && (
        <TUI.Box
          sx={{
            bg: "black",
            color: "white",
            textAlign: "center",
            pt: 3,
            pb: 4,
            mb: 4,
          }}
        >
          <LayoutSection>
            <TUI.Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <TUI.Text variant="f4_bold" sx={{ textAlign: "center", mb: 2 }}>
                Preview Mode
              </TUI.Text>
              <TUI.Text variant="f5">
                You are viewing the page in preview mode. <br />
                The page may refresh automatically when the content in DatoCMS is saved.
              </TUI.Text>
              <TUI.Button
                sx={{ display: "block", mt: 3 }}
                onClick={() => {
                  window.location.href = "https://cyp.ch";
                }}
              >
                Exit Preview Mode
              </TUI.Button>
            </TUI.Box>
          </LayoutSection>
        </TUI.Box>
      )}

      <AnimatePresence>
        <LayoutSection
          ref={menuRef}
          sx={{
            bg: "white",
            position: "relative",
            boxShadow: "elevation3",
            "@media print": {
              boxShadow: "none",
            },
            ...sx,
          }}
        >
          {isExpanded && (
            <MotionBox
              ref={scrollRef}
              key="menu-items"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={springConfigs.slow}
              sx={{
                bg: "grayLight",
                boxShadow: "default",
                py: 4,
                left: 0,
                height: "100vh",
                maxHeight: "100vh",
                position: "fixed",
                width: "100%",
                flexDirection: "column",
                overflowY: "auto",
                zIndex: 2,
              }}
            >
              <LayoutSection>
                {headerMenuItems.map((d, index) => {
                  return (
                    <MotionBox
                      key={d.slug!}
                      sx={{ py: 3, maxWidth: isNarrow ? "calc(100% - 24px)" : "100%" }}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{
                        delay: index / 10,
                        ...springConfigs.slow,
                      }}
                    >
                      {d.isVirtual ? (
                        <TUI.Text variant="f4_mono" sx={{ mb: 2, textDecoration: "underline" }}>
                          {d.label}
                        </TUI.Text>
                      ) : (
                        <Link {...contentPageLinkProps(router.locale, d)} passHref legacyBehavior>
                          <TUI.Link
                            sx={{
                              color: "text",
                              textDecoration: "none",
                              "&:hover": {
                                textDecoration: "none",
                                color: d.domain?.hex,
                              },
                            }}
                            onClick={() => setIsExpanded(false)}
                          >
                            <TUI.Text variant="f4_mono" sx={{ mb: 2 }}>
                              {d.label}
                            </TUI.Text>
                          </TUI.Link>
                        </Link>
                      )}
                      <TUI.Flex sx={{ flexWrap: "wrap" }}>
                        {d.children.map((c, index) => {
                          const isLast = index === d.children.length - 1;
                          const insertBreak = (contentInfo?.contentPageLine || []).map((x) => x.id).includes(c.id);

                          return (
                            <Fragment key={c.slug!}>
                              {insertBreak && <br />}

                              {c.isVirtual ? (
                                <TUI.Text as="span" variant="f6_mono">
                                  {c.label}
                                </TUI.Text>
                              ) : (
                                <TUI.Flex sx={{ alignItems: "center" }}>
                                  <Link {...contentPageLinkProps(router.locale, c)} passHref legacyBehavior>
                                    <TUI.Link
                                      onClick={() => setIsExpanded(false)}
                                      sx={{
                                        color: "text",
                                        textDecoration: "none",
                                        "&:hover": {
                                          textDecoration: "none",
                                          color: c.domain?.hex,
                                        },
                                      }}
                                    >
                                      <TUI.Text as="span" variant="f6_mono">
                                        {c.label}
                                      </TUI.Text>
                                    </TUI.Link>
                                  </Link>
                                  {!isLast && (
                                    <TUI.Text as="span" variant="f6_mono" sx={{ mx: 3, lineHeight: 1.8 }}>
                                      –
                                    </TUI.Text>
                                  )}
                                </TUI.Flex>
                              )}
                            </Fragment>
                          );
                        })}
                      </TUI.Flex>
                    </MotionBox>
                  );
                })}
                <MotionBox
                  key={"external-1"}
                  sx={{ py: 3, color: "grayDark", mb: 6 }}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{
                    delay: headerMenuItems.length / 10,
                    ...springConfigs.slow,
                  }}
                >
                  <TUI.Text variant="f4_mono" sx={{ mb: 2 }}>
                    Links
                  </TUI.Text>
                  <TUI.Link
                    onClick={() => setIsExpanded(false)}
                    href="https://www.cypnet.ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: "grayDark",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                  >
                    <TUI.Text as="span" variant="f6_mono">
                      CYPnet Login
                    </TUI.Text>
                  </TUI.Link>
                </MotionBox>
              </LayoutSection>
            </MotionBox>
          )}

          {noGlobalNotification === true ? null : <GlobalNotification pageId={pageId} />}

          <TUI.Flex sx={{ px: 0, py: 4, position: "relative", alignItems: "center" }}>
            <Link {...homeLinkProps} passHref style={{ position: "relative" }}>
              <TUI.Image
                src="https://www.datocms-assets.com/25771/1679493679-cyp-logo-white-bg.svg"
                alt="CYP logo"
                sx={{
                  visibility: isExpanded ? "hidden" : "visible",
                  width: [125, 200, 260],
                  mr: "auto",
                }}
              />
            </Link>

            <TUI.Flex
              sx={{
                ml: "auto",
                position: "absolute",
                zIndex: 2,
                width: "auto",
                right: 0,
                alignItems: "center",
                "@media print": {
                  display: "none",
                },
              }}
            >
              {!isNarrow && (
                <>
                  {headerMenuItems.map((d) => {
                    return (
                      <TUI.Flex key={d.id} mr={HEADER_MENU_ITEM_MARGIN_RIGHT} sx={{ alignItems: "center" }}>
                        <HeaderHoverableMenuItem
                          parentLabel={d.label}
                          parentLinkProps={d.isVirtual ? null : contentPageLinkProps(router.locale, d)}
                          childItems={d.children.map((c) => ({
                            label: c.label,
                            linkProps: c.isVirtual ? null : contentPageLinkProps(router.locale, c),
                          }))}
                        ></HeaderHoverableMenuItem>
                      </TUI.Flex>
                    );
                  })}
                  <TUI.Link
                    href="https://www.cypnet.ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      mr: HEADER_MENU_ITEM_MARGIN_RIGHT,
                      py: 1,
                      px: 2,
                      color: (theme) => theme.colors.cypnetBlue,
                      textDecoration: "none",
                      border: (theme) => `1px solid ${theme.colors.cypnetBlue}`,
                      borderRadius: "8px",
                    }}
                  >
                    <TUI.Text variant="f5_bold">CYPnet</TUI.Text>
                  </TUI.Link>
                </>
              )}

              {!isExpanded && alternates.length > 1 && (
                <TUI.Box mr={HEADER_MENU_ITEM_MARGIN_RIGHT}>
                  <LocaleSwitcher alternates={alternates} />
                </TUI.Box>
              )}

              {isExpanded ? (
                <TUI.Box onClick={() => setIsExpanded(!isExpanded)}>
                  <Icons.Close24 />
                </TUI.Box>
              ) : isNarrow ? (
                <>
                  <TUI.Link
                    onClick={() => setIsExpanded(!isExpanded)}
                    sx={{
                      color: "text",
                    }}
                  >
                    <TUI.Text variant="f5_mono"> {t("menu")}</TUI.Text>
                  </TUI.Link>
                </>
              ) : null}
            </TUI.Flex>
          </TUI.Flex>
        </LayoutSection>
      </AnimatePresence>
    </TUI.Box>
  );
}

export default Header;
