import React from "react";
import * as TUI from "theme-ui";

const Root = TUI.Button;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  variant?: "primary" | "accent" | "ghost";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

function Button(props: Props, ref: React.Ref<HTMLButtonElement>) {
  const { startIcon, endIcon, sx, children, ...rest } = props;

  return (
    <Root
      ref={ref}
      {...rest}
      sx={{ display: "inline-flex", alignItems: "center", ...sx }}
    >
      {startIcon && <TUI.Box sx={{ ml: -1, mr: 2 }}>{startIcon}</TUI.Box>}
      {children}
      {endIcon && <TUI.Box sx={{ ml: 2, mr: -1 }}>{endIcon}</TUI.Box>}
    </Root>
  );
}

export default React.forwardRef(Button);
