import { forwardRef } from "react";
import * as TUI from "theme-ui";

/**
 * Layout Sections
 */

interface Props extends TUI.BoxProps {}

export const LayoutSection = forwardRef<
  HTMLDivElement,
  Props & { wide?: boolean }
>(({ children, wide, sx }, ref) => {
  const maxWidth = wide ? "100rem" : "96rem";
  return (
    <TUI.Grid
      ref={ref}
      columns={[
        `0 minmax(240px, 100vw) 0`,
        `auto minmax(280px, ${maxWidth}) auto`,
      ]}
      sx={{ ...sx }}
    >
      <div style={{ gridColumn: 2 }}>{children}</div>
    </TUI.Grid>
  );
});
