import { Maybe } from "@/gql";
import { AnimatePresence } from "framer-motion";
import Link, { LinkProps } from "next/link";
import { useEffect, useRef, useState } from "react";
import * as TUI from "theme-ui";
import { MotionBox } from "..";

const ANIMATION_DURATION = 0.25;

interface Props {
  parentLabel?: Maybe<string>;
  parentLinkProps?: (LinkProps & { as: string }) | null;
  childItems?: Array<{
    label?: Maybe<string>;
    linkProps: (LinkProps & { as: string }) | null;
  }>;
  alignCenter?: boolean;
}

function HeaderHoverableMenuItem(props: Props) {
  const { parentLabel, parentLinkProps, childItems, alignCenter } = props;
  const [isExpanded, setExpanded] = useState(false);

  const childItemsContainerRef = useRef<HTMLDivElement>(null);
  const [childItemsContainerWidth, setChildItemsContainerWidth] = useState(0);

  useEffect(() => {
    if (alignCenter) {
      const width = childItemsContainerRef.current?.clientWidth;

      if (width) {
        setChildItemsContainerWidth(width);
      }
    }
  });

  return (
    <TUI.Box
      sx={{
        position: "relative",
        borderTop: isExpanded ? "2px solid black" : "2px solid white",
        py: 2,
        transition: `border-top ${ANIMATION_DURATION}s`,
      }}
      onMouseLeave={() => setExpanded(false)}
    >
      <TUI.Box onMouseEnter={() => setExpanded(true)}>
        {parentLinkProps ? (
          <HoverableMenuLink linkProps={parentLinkProps} label={parentLabel} isChild={false} />
        ) : (
          <HoverableMenuItemLabel label={parentLabel} />
        )}
      </TUI.Box>
      <AnimatePresence>
        {isExpanded && childItems?.length && (
          <MotionBox
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: 5, opacity: 1 }}
            exit={{ y: 0, opacity: 0 }}
            transition={{ type: "tween", duration: ANIMATION_DURATION }}
          >
            <TUI.Flex
              ref={childItemsContainerRef}
              sx={{
                position: "absolute",
                top: "100%",
                width: "max-content",
                flexDirection: "column",
                bg: "white",
                pt: 3,
                left: alignCenter ? `calc(-${Math.round(childItemsContainerWidth / 2)}px + 50%)` : "0%",
              }}
            >
              {childItems.map((item, index) =>
                item.linkProps ? (
                  <HoverableMenuLink key={index} linkProps={item.linkProps} label={item.label} isChild={true} />
                ) : (
                  <HoverableMenuItemLabel key={index} label={item.label} hasAdditionalPadding={true} />
                )
              )}
            </TUI.Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </TUI.Box>
  );
}

export default HeaderHoverableMenuItem;

const HoverableMenuLink = ({
  label,
  linkProps,
  isChild,
}: {
  label: Maybe<string> | undefined;
  linkProps: LinkProps & { as: string };
  isChild: boolean;
}) => {
  return (
    <Link {...linkProps} passHref legacyBehavior>
      <TUI.Link
        sx={{
          textDecoration: "none",
        }}
      >
        <HoverableMenuItemLabel label={label} isClickable={true} hasAdditionalPadding={isChild} />
      </TUI.Link>
    </Link>
  );
};

const HoverableMenuItemLabel = ({
  label,
  isClickable = false,
  hasAdditionalPadding = false,
}: {
  label: Maybe<string> | undefined;
  isClickable?: boolean;
  hasAdditionalPadding?: boolean;
}) => {
  const padding = hasAdditionalPadding ? { py: 3, px: 4 } : {};

  return (
    <TUI.Text
      variant="f5_bold"
      sx={{
        color: "black",
        cursor: isClickable ? "pointer" : "default",
        ...padding,
      }}
    >
      {label}
    </TUI.Text>
  );
};
