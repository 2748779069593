/*
 * !!! THIS IS A GENERATED FILE – DO NOT EDIT !!!
 */

import React from "react";

export const Close18 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={18} height={18} fill="none" viewBox="0 0 18 18" {...props}>
    <path fill="currentColor" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9l4.47-4.47z" />
  </svg>
));

export const Close24 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>
));

export const __descriptor_Close = {
  name: "Close",
  instances: [
    { size: 18, Component: Close18 },
    { size: 24, Component: Close24 },
  ],
} as const;
