import { FaviconQuery } from "../../../generated/graphql";

export const favicon: FaviconQuery["site"]["favicon"] = [
  {
    __typename: "Tag",
    attributes: {
      sizes: "16x16",
      type: "image/png",
      rel: "icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=16&w=16",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "32x32",
      type: "image/png",
      rel: "icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=32&w=32",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "96x96",
      type: "image/png",
      rel: "icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=96&w=96",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "192x192",
      type: "image/png",
      rel: "icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=192&w=192",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "57x57",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=57&w=57",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "60x60",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=60&w=60",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "72x72",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=72&w=72",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "76x76",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=76&w=76",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "114x114",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=114&w=114",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "120x120",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=120&w=120",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "144x144",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=144&w=144",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "152x152",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=152&w=152",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      sizes: "180x180",
      rel: "apple-touch-icon",
      href: "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=180&w=180",
    },
    content: null,
    tag: "link",
  },
  {
    __typename: "Tag",
    attributes: {
      name: "msapplication-square70x70logo",
      content:
        "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=70&w=70",
    },
    content: null,
    tag: "meta",
  },
  {
    __typename: "Tag",
    attributes: {
      name: "msapplication-square150x150logo",
      content:
        "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=150&w=150",
    },
    content: null,
    tag: "meta",
  },
  {
    __typename: "Tag",
    attributes: {
      name: "msapplication-square310x310logo",
      content:
        "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=310&w=310",
    },
    content: null,
    tag: "meta",
  },
  {
    __typename: "Tag",
    attributes: {
      name: "msapplication-square310x150logo",
      content:
        "https://www.datocms-assets.com/25771/1666961651-favicon-1200x1200.png?auto=format&h=150&w=310",
    },
    content: null,
    tag: "meta",
  },
];
