import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import * as TUI from "theme-ui";

export const MotionBox = motion(TUI.Box, {
  forwardMotionProps: true,
});
export const MotionGrid = motion(TUI.Grid, {
  forwardMotionProps: true,
});

export const springConfigs = {
  slow: {
    type: "spring",
    stiffness: 180,
    damping: 25,
  },
  gentle: {
    type: "spring",
    stiffness: 200,
    damping: 20,
  },
  lively: {
    type: "spring",
    damping: 15,
    stiffness: 100,
  },
};

type PageKey = "module" | "index" | "partner" | "blogindex";

interface Props extends TUI.BoxProps {
  pageKey: PageKey | string;
}

/**
 * @deprecated This component does nothing (other than render a div around the
 * children), it can be safely removed.
 */
export const PageTransition: FunctionComponent<Props> = ({ sx, children }) => {
  return <TUI.Box sx={sx}>{children}</TUI.Box>;
};
